<template>
  <div>
    <Timeline style="padding-top: 5px;">
      <TimelineItem v-if="checkedData.status>1&&(midData[0].status===2||midData[0].status===1)">
        <Icon type="md-help-circle" slot="dot"/>
        <ButtonGroup style="margin-top: -6px;">
          <Button type="primary" @click="modShow=true;editData=[]">审核/更新风险</Button>
        </ButtonGroup>
      </TimelineItem>
      <template v-for="(item,index) in rebackList">
        <TimelineItem :key="item.id" v-if="index<nums">
          <Icon type="md-albums" slot="dot" v-if="item.type===3"></Icon>
          <Icon type="md-contact" slot="dot" v-else-if="item.type===2"></Icon>
          <Icon type="ios-home" slot="dot" v-else></Icon>
          <Alert :type="item.type===3?'error':(item.type===2?'warning':'info')" class="reback">
            <div class="content">
              <div class="time">
                <h1 class="h1Title">
                  {{ item.title }}
                  <Tag :color="item.status===1?'red':'green'" class="status">{{ item.status|getBaseDataName('隐患回复状态') }}</Tag>
                </h1>
              </div>
              <div class="desc">{{ item.desc }}</div>
              <div class="desc">
                <div v-viewer="{url: 'data-src'}">
                  <ys-image
                      :radius="3"
                      v-for="subItem in (item.fileList||[])"
                      :key="subItem.id"
                      :src="config.baseURL+'/resource/GetFile/'+subItem.id+'?size=100'"
                      :data-src="config.baseURL+'/resource/GetFile/'+subItem.id"
                      width="50"
                      height="50">
                  </ys-image>
                </div>
              </div>
              <div class="createTime">
                <Icon type="ios-contact-outline" size="15" style="margin:0 3px;"/>
                {{ item.createdUser }}&nbsp;&nbsp;
                <Icon type="ios-clock-outline" size="15" style="margin-right: 5px;"/>
                {{ item.createdAt }}
              </div>
              <!--              <div class="opreationBtn" v-if="checkedData.status===3&&midData[0].status===2&&item.createdBy===userInfo.id&&(new Date().getTime()-new Date(item.updatedAt).getTime()<=60*1000*5)">-->
              <!--                <ButtonGroup>-->
              <!--                  <Button size="small" icon="md-create" ghost type="primary" @click="edit(index)">修改</Button>-->
              <!--                  <Button size="small" icon="md-close" ghost type="primary" @click="delItem(index)">删除</Button>-->
              <!--                </ButtonGroup>-->
              <!--              </div>-->
            </div>
          </Alert>
        </TimelineItem>
      </template>
      <TimelineItem v-if="rebackList.length>1">
        <Icon type="ios-remove-circle" slot="dot"/>
        <a href="javascript:;" @click="showAll">{{ rebackList.length !== nums ? ('查看更早的 ' + (rebackList.length - nums) + ' 条记录>>') : '收起更早记录' }}</a>
      </TimelineItem>
      <TimelineItem v-else-if="rebackList.length===0">
        <Icon type="ios-remove-circle" slot="dot"/>
        <Alert v-if="rebackList.length===0">还没有整改回复记录！</Alert>
      </TimelineItem>
    </Timeline>
    <ys-modal v-model="modShow" :width="700" title="审核整改结果">
      <add-reback @on-ok="back" :modData="editData" :midData="midData"></add-reback>
    </ys-modal>
  </div>
</template>

<script>
import {getProblemsRebackList} from "@/api/amend";
import AddReback from "./addReback.vue";
import {mapGetters} from "vuex";

export default {
  name: 'addReadBack',
  components: {AddReback},
  props: {
    midData: {
      type: Array,
      default: () => []
    },
    checkedData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      editData: [],
      rebackList: [],
      nums: 1,
      modShow: false
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'config'])
  },
  mounted() {
    this.getList();
  },
  methods: {
    back() {
      this.getList();
      this.$emit('on-ok')
    },
    edit(index) {
      this.editData = [this.rebackList[index]];
      this.modShow = true;
    },
    delItem(index) {
      this.$Modal.confirm({
        title: '提示',
        content: '确认要删除这条记录么?一旦删除将无法恢复!',
        okText: '删除',
        cancelText: '取消',
        onOk: () => {
          this.$del('/amend/comment/reply/Remove', {id: this.rebackList[index].id}).then((res) => {
            if (res.code === 200) {
              this.$Notice.success({
                title: '提示',
                desc: '操作成功！'
              });
              this.getList();
            }
          });
        }
      });
    },
    showAll() {
      this.nums = this.nums === this.rebackList.length ? (this.rebackList.length >= 1 ? 1 : 0) : this.rebackList.length
    },
    getList() {
      if (this.midData.length === 1) {
        getProblemsRebackList({checkCommentId: this.midData[0].id, limit: -1}).then((res) => {
          this.rebackList = res.data || []
          this.nums = this.rebackList.length >= 1 ? 1 : 0
        })
      }

    },
  },


}
</script>

<style scoped lang="less">
.ivu-alert {
  margin-bottom: 0;
}

.ivu-timeline-item {
  padding-bottom: 0 !important;
}

.reback {
  padding: 0 16px 8px 16px
}

.content {
  .time {
    padding-bottom: 5px;

    .h1Title {
      position: relative;
      .status{
        position: absolute;
        right: 0;
        top: 3px;
      }
    }
  }

  .title {
    padding-bottom: 10px;
  }

  .desc {
    line-height: 22px;
    padding-bottom: 5px;
  }

  .createTime {
    color: #959595;
    border-top: 1px solid #e0e0e0;
    padding-top: 8px;
    font-size: 12px;
  }

  .opreationBtn {
    padding-top: 5px;
  }

}


</style>