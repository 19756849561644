<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="80">
      <Alert v-if="midData.length===1&&midData[0].status===1">当前风险施工企业暂未整改，请注意！</Alert>
      <FormItem label="审核状态" prop="status">
        <RadioGroup v-model="form.status">
          <Radio :label="item.value" border v-for="item in baseData['隐患回复状态'].filter(item=>item.value!==2&&item.value!==4)" :key="item.value">{{ item.name }}</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="回复" prop="desc" style="width: 100%">
        <Input v-model="form.desc" type="textarea" maxlength="128" show-word-limit :autosize="{minRows: 3,maxRows: 5}" placeholder="回复"></Input>
      </FormItem>
      <FormItem label="附件" prop="fileList" style="width: 100%;text-align: center">
        <ys-upload :action="config.baseURL+'/resource/UploadFile'" :defaultList="fileList" @on-success="bLFile" :headers="{token:token}" type="halfList" :format="['jpg','jpeg','png','gif']"></ys-upload>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok(form.id?'/amend/comment/reply/Edit':'/amend/comment/reply/Add')">确定</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit';

export default {
  name: '',
  mixins: [edit],
  data() {
    return {
      ruleValidate: {
        desc: [
          {type: 'string', required: true, message: '回复内容必填', trigger: 'blur'}
        ],
      },
      fileList: [],
      form: {
        checkCommentId: '',
        id: null,
        desc: '',
        status: 3,
        fileList: [],
      }
    }
  },
  mounted() {
    if (this.modData && this.modData.length > 0) {
      Object.keys(this.form).forEach((item) => {
        this.form[item] = this.modData ? this.modData[0][item] : null
      })
      this.fileList = this.modData[0].fileList || [];
      this.form.fileList = this.fileList.map((item) => item.id)
    } else {
      this.form.checkCommentId = this.midData[0].id;
    }
  },
  methods: {
    bLFile(data) {
      this.form.fileList = data.map((item) => item.id)
    }
  }
}
</script>
